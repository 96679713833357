<template>
    <div class="search-box">
        <div class="search flex x-center y-center">
            <div class="search-wrapper">
                <img class="search-img" src="~@/assets/images/cmcad-logo.png" alt="">
                <div class="fs18 strong mt10 title">中国医学学术会议论文摘要数据库</div>
                <div class="flex x-left search-inner">
                    <a-input-search class="mt35 search-input" placeholder="输入专家姓名、题目、关键字等" enter-button="搜索"
                        size="large" @search="onSearch">
                        <a-select :default-value="typeList[0].name" slot="addonBefore" v-if="typeList.length" placeholder="类型"
                            style="width:120px;" class="mr10" @change="handleChangeType">
                            <a-select-option :selected="item.selected" v-for="(item, index) in typeList"
                                :value="item.id" :key="index">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-input-search>
                    <div class="mt35 ml20 flex flex-column x-center btn-text">
                        <router-link class="advanced-search fs14"
                            :to="{ path: '/database/advanced-search' }">高级搜索</router-link>
                    </div>
                </div>

            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "@/components/layout/layout-footer";

export default {
    name: "databaseSearch",
    components: {
        Footer,
    },
    data() {
        return {
            currSelected: undefined,
            typeList: [
                { id: 'title', name: '标题', selected: true },
                { id: 'one_real_name', name: '第一作者', selected: false },
                { id: 'one_org_cnname', name: '第一作者单位', selected: false },
                { id: 'all_name', name: '所有作者', selected: false },
                { id: 'keywords', name: '关键词', selected: false },
                { id: 'content', name: '内容', selected: false },
                { id: 'cn_title', name: '来源', selected: false },
            ],
            __search_path: '',
            search_type:'title',
        }
    },
    created() {

    },

    methods: {
        onSearch(search_name) {
            this.$router.push({
                path: '/database/index',
                query: {
                    search_name,
                    search_type: this.search_type
                }
            })
        },
        handleChangeType(e) {
            this.search_type = e
            this.__search_path = e;
        },
    }
}
</script>

<style scoped lang="less">
.search-box {
    height: calc(100vh - 170px);

    .cursor {
        cursor: pointer;
    }

    .search {
        height: 100%;

        .title {
            color: #004DA1;
        }

        &-img {
            width: 53px;
            height: 54px;
        }

        &-input {
            width: 650px;
            height: 44px;
        }

        /deep/.ant-input-lg {
            height: 44px;
        }

        /deep/.ant-btn-lg {
            height: 44px;
            width: 100px;
            background: #1E51C9;
            border-color: transparent;
            font-size: 14px;
            font-weight: bold;
        }
        /deep/.ant-input-group-addon{
            background: transparent;
        }
        .advanced-search {
            color: #1E51C9;
        }
    }
}
@media screen and (max-width:768px){
    .search-box{
        width: 100%;
        height: 100vh;
        margin-top: -50px;
        .search{
            .search-wrapper{
                width: 100%;
                .search-inner{
                    display: block;
                    .btn-text{
                        text-align: center;
                        margin-top: 10px;
                    }
                }
            }
            width: 100%;
            padding: 0 10px;
            &-input{
                width: 100%;
            }
        }
    }
}
</style>